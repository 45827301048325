import { createElement, ReactNode } from 'react'
import cx from 'classnames'

import BlockContent from '@sanity/block-content-to-react'

import { SanityContentBlock } from '@data/sanity/queries/types/content'

interface BlockProps {
  node: SanityContentBlock
  children: ReactNode
}

const Block = (props: BlockProps) => {
  const { style = 'normal' } = props.node

  // P tags can't contain list elements
  const hasNestedList = props.node.children?.some((child: any) => child._type === 'list');
  if (hasNestedList) {
    return (
      <span
        className={cx({
          'is-small paragraph': style.includes('small'),
          'is-medium paragraph': style.includes('medium'),
          'is-large paragraph': style.includes('large'),
        })}
      >
        {props.children}
      </span>
    );
  }

  if (style.includes('none')) {
    return <>{props.children}</>
  }

  if (style.includes('small')) {
    return <p className="is-small">{props.children}</p>
  }

  if (style.includes('medium')) {
    return <p className="is-medium">{props.children}</p>
  }

  if (style.includes('large')) {
    return <p className="is-large">{props.children}</p>
  }

  // build our mock header styles
  if (style.includes('mock')) {
    return (
      <p
        className={cx({
          'is-h1': style.includes('h1'),
          'is-h2': style.includes('h2'),
          'is-h3': style.includes('h3'),
          'is-h4': style.includes('h4'),
          'is-h5': style.includes('h5'),
        })}
      >
        {props.children}
      </p>
    )
  }

  /// go through our remaing, true header styles
  if (/^h\d/.test(style)) {
    return createElement(style, {}, props.children)
  }

  // Handle all other blocks with the default serializer
  return BlockContent.defaultSerializers.types.block(props)
}

Block.displayName = 'Block'

export default Block
