import { ReactNode } from 'react'

interface ListProps {
  type: string
  children: ReactNode
  className: string
}

export const CustomList: React.FC<ListProps> = ({ type, children, className }) => {
  switch (type) {
    case 'bullet':
      return <ul className={`list-disc ml-10 ${className}`}>{children}</ul>
    case 'number':
      return <ol className={`list-decimal ml-10 ${className}`}>{children}</ol>
    default:
      return <ul className={`list-disc ml-10 ${className}`}>{children}</ul>
  }
}
